/* Ensure you include this in your CSS file or within a <style> tag in your component */

/* Base styles for the table */
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Responsive styles for mobile view */
@media (max-width: 767px) {
  .table {
    border: 0;
  }

  .table thead {
    display: none;
  }

  .table tbody {
    display: block;
  }

  .table tr {
    display: block;
    margin-bottom: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 0.5rem;
  }

  .table td,
  .table th {
    display: block;
    text-align: left;
    padding: 0.75rem;
    border: none;
    border-bottom: 1px solid #dee2e6;
  }

  .table td:last-child,
  .table th:last-child {
    border-bottom: 0;
  }

  .table td:before {
    content: attr(data-label);
    font-weight: bold;
    display: inline-block;
    width: 120px;
    padding-right: 10px;
    
  }

  .product-thumbnail,
  .product-name,
  .product-price {
    display: block;
  }

  .product-name > a,
  .product-price > a {
    display: inline-block;
    margin-left: 10px;
  }

  .product-name > a i,
  .product-price > a i {
    font-size: 16px;
    padding: 10px;
    display: inline-block;
  }
}
