.cart-table {
  width: 100%;
}

/* Mobile styles */
/* Mobile styles */
@media (max-width: 768px) {
  .cart-table {
    display: block;
    overflow-x: auto;
  }

  .proo {
    margin-top: 20px;
  }
  .cart-table table {
    width: 100%;
    border-collapse: collapse;
  }

  .cart-table th,
  .cart-table td {
    padding: 10px;
    text-align: left;
    display: block;
  }

  .cart-table th {
    display: none;
  }

  .cart-table td {
    position: relative;
    padding-left: 50%;
    border-bottom: 1px solid #ddd;
  }

  .cart-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: 50%;
    padding-left: 10px;
    font-weight: bold;
    color: #555;
  }

  /* Align product image to the right */
  .product-thumbnail {
    text-align: right;
    padding-left: 0; /* Remove padding for right alignment */
  }

  .product-thumbnail img {
    display: inline-block;
    width: 80px; /* Adjust as needed */
    height: auto;
  }

  .input-counter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .minus-btn,
  .plus-btn {
    cursor: pointer;
  }

  .default-btn {
    width: 100%;
    margin: 10px 0;
  }

  .inner-title h3 {
    text-align: center;
  }
}
