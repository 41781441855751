.active-variant {
  background-color: rgb(81, 81, 81) !important;
  color: white !important;
  border: none !important;
}
@media (max-width: 767px) {
  .tabs li.active {
    background-color: rgb(
      234 238 230
    ) !important; /* Mobile active background color */
    border: 1px solid #129112 !important;
    padding: 8px 10px !important;
    border-radius: 5px; /* Optional: adds rounded corners */
  }
}
.tabs li {
  padding: 10px 20px;
  cursor: pointer;
  background-color: rgb(234 238 230); /* Background color for all tabs */
  border: 1px solid rgb(234 238 230); /* Border color for all tabs */
  color: #333; /* Text color */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition */
  border-radius: 4px;
}

/* Active tab styles */
.tabs li.active {
  background-color: #eaeee6; /* Background color for active tab */
  border: 1px solid rgb(72, 139, 4); /* Border color for active tab */
  color: #fff; /* Text color for active tab */
}
